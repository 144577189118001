import { twMerge } from '@troon/tailwind-preset/merge';
import type { JSX } from 'solid-js';

export function Table(props: JSX.HTMLAttributes<HTMLTableElement>) {
	return (
		<div class="w-full overflow-x-auto">
			<table {...props} class="w-full border-separate border-spacing-0">
				{props.children}
			</table>
		</div>
	);
}

export function Thead(props: JSX.HTMLAttributes<HTMLTableSectionElement>) {
	return (
		<thead class="[&_th:first-of-type]:rounded-tl [&_th:first-of-type]:border-l [&_th:last-child]:rounded-tr [&_th:last-child]:border-r [&_th]:border-y [&_th]:border-neutral [&_th]:bg-white [&_th]:px-8 [&_th]:py-4">
			{props.children}
		</thead>
	);
}

export function Tbody(props: JSX.HTMLAttributes<HTMLTableSectionElement>) {
	return (
		<thead class="[&>tr:last-child>td:first-child]:rounded-bl [&>tr:last-child>td:last-child]:rounded-br [&>tr:last-child>th:first-child]:rounded-bl [&>tr:last-child>th:last-child]:rounded-br [&_td:first-child]:border-l [&_td:last-child]:border-r [&_td]:border-b [&_td]:border-neutral [&_td]:bg-white [&_td]:px-8 [&_td]:py-4 [&_th:first-child]:border-l [&_th:last-child]:border-r [&_th]:border-b [&_th]:border-neutral [&_th]:bg-white [&_th]:px-8 [&_th]:py-4">
			{props.children}
		</thead>
	);
}

export function Tr(props: JSX.HTMLAttributes<HTMLTableRowElement>) {
	return <tr {...props} />;
}

export function Th(props: JSX.ThHTMLAttributes<HTMLTableCellElement>) {
	return <th {...props} class={twMerge('text-start group-hover:bg-brand-100', props.class)} />;
}

export function Td(props: JSX.TdHTMLAttributes<HTMLTableCellElement>) {
	return <td {...props} class={twMerge('align-middle group-hover:bg-brand-100', props.class)} />;
}
